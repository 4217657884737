<template>
  <Layout :title="$t('waterMark.tips2')">
    <div :class="prefixCls">
      <div :class="prefixCls + '__body'">
        <HaiUpload @onChange="onChangeFile"></HaiUpload>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import HaiUpload from '@/components/HaiUpload'

const prefixCls = 'watermark-detection-page'
import { GetWatermarkDetect, GetWatermarkDetectResult } from '../api/create'
export default {
  name: 'WatermarkDetection',
  components: {
    Layout,
    HaiUpload
  },

  data() {
    return {
      prefixCls,
      currentRes: 0
    }
  },

  methods: {
    async onChangeFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      this.currentRes = await this.$Loading.startTo(0, 20, this.$t('wait'))
      try {
        const response = await GetWatermarkDetect(formData)

        if (response.code === 200) {
          await this.getFetchResult(response.data.taskId)
        } else {
          this.toNextPage()
        }
      } catch (e) {
        this.toNextPage()
      }
    },

    async getFetchResult(taskId) {
      const response = await GetWatermarkDetectResult({ taskId })
      if (response.code >= 300 && response.code <= 304) {
        if (this.currentRes === response.data.progress) {
          setTimeout(() => {
            this.getFetchResult(taskId)
          }, 2000)
        } else {
          if (this.currentRes < response.data.progress) {
            this.currentRes = await this.$Loading.startTo(
              this.currentRes,
              response.data.progress,
              response.data.text[this.$i18n.locale]
            )
            await this.getFetchResult(taskId)
          } else {
            setTimeout(() => {
              this.getFetchResult(taskId)
            }, 2000)
          }
        }
      } else {
        this.toNextPage(response.data)
      }
    },
    toNextPage(data) {
      window.sessionStorage.setItem('watermark_detection', JSON.stringify(data))
      this.$router.push({ name: 'watermarkDetectionResult' })
      this.$Loading.finish()
    }
  }
}
</script>

<style lang="scss">
$prefixCls: 'watermark-detection-page';
.#{$prefixCls} {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &__body {
    width: 440px;
    padding: 0 20px;
    margin-top: 180px;
  }
}
</style>
